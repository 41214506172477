import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaCopy, FaEdit, FaTrash } from "react-icons/fa";

import { PageWrapper } from "../components/admin";
import OrgMap from "../components/admin/organization/orgMap";
import { toast } from "react-toastify";
import { Button } from "../components/form";
import { BaseModal } from "../components/popups";
import { FullScreenLoader } from "../components/common";

import {
  deleteProfessionMap,
  getProfessionMaps,
  getProfessions,
  getProfessionsAccessibleToOptions,
  saveProfessionMap
} from "../service";
import { uploadFileIfNeeded } from "../utils";

export default function OrganisationMaps() {
  const [professions, setProfessions] = useState([]);
  const [maps, setMaps] = useState([]);
  const [editMap, setEditMap] = useState(null);
  const [deleteMap, setDeleteMap] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accessibleProfessions, setAccessibleProfessions] = useState([]);
  const [professionsFilter, setProfessionsFilter] = useState([]);
  const { orgId } = useParams();

  const fetchProfessions = async () => {
    try {
      setLoading("Loading maps data...");
      const { data } = await getProfessions(orgId, "all");
      const { data: accessibleProfessions } = await getProfessionsAccessibleToOptions(orgId);
      setProfessions(data);
      setAccessibleProfessions([
        { label: "", value: "" },
        ...accessibleProfessions.map((profession) => ({ label: profession, value: profession }))
      ]);
      fetchProfessionMaps();
    } catch (error) {
      console.error("Fetch professions error", error);
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const fetchProfessionMaps = async () => {
    try {
      setLoading("Loading profession maps...");
      const { data } = await getProfessionMaps(orgId);
      setMaps(data);
    } catch (error) {
      toast.error("Failed to fetch profession maps");
    } finally {
      setLoading(false);
    }
  };

  const saveMap = async (map) => {
    try {
      setLoading("Saving map...");

      const { image } = map;

      const mapImageLink = (await uploadFileIfNeeded(image, "profession-maps")) || image;

      let updatedMap = {
        ...map,
        image: mapImageLink
      };

      let professionNumbersCopy = [...updatedMap.professionNumbers];

      let index = 0;
      for (let professionNumber of professionNumbersCopy) {
        if (professionNumber?.image) {
          const professionImageLink = await uploadFileIfNeeded(
            professionNumber.image,
            "profession-maps-columns-images"
          );
          professionNumber.image = professionImageLink;
          professionNumbersCopy[index] = professionNumber;
        }

        index++;
      }

      updatedMap.professionNumbers = professionNumbersCopy;

      await saveProfessionMap(orgId, updatedMap);

      setEditMap(null);

      await fetchProfessionMaps();
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Failed to save map");
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteMap = async (map) => {
    try {
      setLoading("Deleting map...");

      await deleteProfessionMap(orgId, map._id);

      setDeleteMap(null);

      await fetchProfessionMaps();
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Failed to delete map");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!orgId) return;

    fetchProfessions();
  }, [orgId]);

  useEffect(() => {
    if (!editMap?.professionsFilter) {
      setProfessionsFilter(professions);
      return;
    }

    let filteredProfessions = professions;
    filteredProfessions = [...professions]
      .filter((profession) => profession.accessibleTo?.includes(editMap.professionsFilter))
      .sort((a, b) => a.jobRole_fr.localeCompare(b.jobRole_fr));
    setProfessionsFilter(filteredProfessions);
  }, [editMap?.professionsFilter]);

  return (
    <div>
      <PageWrapper pageHeading={"Organisation Profession Maps"}>
        {maps.map((map, index) => (
          <div
            key={index}
            className="flex flex-row justify-between items-center bg-[#ffffff] shadow-sm mb-4">
            <div className="w-full rounded relative">
              <div className="flex justify-start items-center gap-4 px-4 py-3 cursor-pointer">
                {map?.title ?? `Map ${index + 1}`}
              </div>
            </div>
            <div className="cursor-pointer" onClick={() => setEditMap({ ...map })}>
              <FaEdit />
            </div>
            <div className="cursor-pointer ml-2" onClick={() => setDeleteMap({ ...map })}>
              <FaTrash />
            </div>
            <div
              className="cursor-pointer ml-2"
              onClick={() => {
                const newMap = {
                  ...map,
                  title: map.title + " (Copy)",
                  _id: new Date().getTime()
                };
                setMaps([...maps, newMap]);
                setEditMap(newMap);
              }}>
              <FaCopy />
            </div>
          </div>
        ))}

        <Button.ButtonOutlined
          className="my-3 w-full font-bold"
          handler={() => {
            const newMap = {
              _id: new Date().getTime(),
              title: "Floor Plan Title",
              isTitleVisible: true,
              image: null,
              pins: [],
              professionNumbers: [],
              footer: {
                text: "Footer Text",
                qrLink: "https://www.mission-future.com",
                script: "console.log('Hello')"
              }
            };
            setMaps([...maps, newMap]);
            setEditMap(newMap);
          }}>
          Add new map
        </Button.ButtonOutlined>

        <BaseModal
          open={editMap ? true : false}
          setOpen={setEditMap}
          modalSize="w-[220mm] sm:w-[220mm]"
          title={editMap?.title ? "Edit " + editMap?.title : "Edit Map"}
          customButtonTitle="Save"
          customButtonAction={() => {
            saveMap(editMap);
          }}>
          {editMap && (
            <OrgMap
              map={editMap}
              setMap={setEditMap}
              professions={professionsFilter ? professionsFilter : professions}
              accessibleProfessions={accessibleProfessions}
            />
          )}
        </BaseModal>

        <BaseModal
          open={deleteMap ? true : false}
          setOpen={setDeleteMap}
          title={`Are you sure you want to delete '${deleteMap?.title}'`}>
          {deleteMap && (
            <>
              <p className="text-base text-black">This action cannot be undone</p>
              <Button.ButtonOutlined
                className={
                  "border-red-500 flex items-center justify-center w-full text-red-500 gap-2 mt-2"
                }
                handler={() => confirmDeleteMap(deleteMap)}>
                <span className="w-4">
                  <FaTrash />
                </span>
                Delete
              </Button.ButtonOutlined>
            </>
          )}
        </BaseModal>

        {loading && <FullScreenLoader text={loading} />}
      </PageWrapper>
    </div>
  );
}
