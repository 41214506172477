import React from "react";

import { Input, Button, InputSelect } from "../../../../form";
import ProfessionMapSelection from "./classProfessionMapSelection";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ClassStudentComponents, { ClassSudentComponentsPicker } from "./classStudentComponents";
import ToggleComponent from "../../../../common/toggleComponent";

const ClassProjectSetup = ({
  region = "default",
  classProjectTypes,
  setClassProjectTypes,
  regions,
  professionMaps,
  systemAdminGroups,
  professionTypes,
  regionalProjectTypes,
  displayToggle,
  setDisplayToggle,
  updateClassProjectTitle,
  duplicateClassProjectTypeForRegion,
  addClassStudentComponent,
  deleteClassStudentComponent,
  onDragEndClassStudentSetup,
  updateBackgroundHeading,
  updateForegroundHeading,
  updateSubText,
  updateButtonText,
  updateHTMLBox,
  updateVideosPlayerTitle,
  updateVideosPlayerUrl,
  addNewVideoInVideosPlayer,
  deleteVideoInVideosPlayer,
  toggleVideoPlayerAptitudeCloud,
  selectProjectTypeRegionalLink,
  selectUsersGroup,
  selectProfessionType,
  toggleUseOrganizationStrengthsListInAptitudeCloud,
  toggleUseOrganizationRolesListInAptitudeCloud,
  updateAptitudeCloudOption,
  addAptitudeCloudOption,
  deleteAptitudeCloudOption,
  addCustomVideoOption,
  deleteCustomVideoOption,
  updateCustomVideoOption,
  saveTeacherAdminSetup,
  deleteRegionalClassProjectType,
  deleteClassProject,
  duplicateClassProject,
  toggleClassProjectTypeEnable,
  toggleShowClassStudentComponentInPdf,
  setOrderOfClassProject
}) => {
  const tabRegion = regions.find((r) => r._id === region);
  const tabRegionName =
    region === "default"
      ? "Default"
      : tabRegion?.name_en || tabRegion?.name_de || tabRegion?.name_fr || tabRegion?.name_it;

  return (
    <div className="my-10 border-[1px] border-[#606060] rounded-md p-4 bg-white">
      <p
        className="font-bold text-lg mb-2 cursor-pointer select-none"
        onClick={() => {
          setDisplayToggle({
            ...displayToggle,
            [`projectTypes-${region}`]: !displayToggle?.[`projectTypes-${region}`]
          });
        }}>
        ({tabRegionName ? tabRegionName : "Default"}) Class Project Type/s
      </p>

      {displayToggle?.[`projectTypes-${region}`] && region !== "default" && (
        <div className="my-4">
          <label className="block font-bold mb-1" htmlFor={region}>
            Region-specific
          </label>
          <InputSelect
            name={region}
            defaultValue={{ value: region, label: tabRegionName }}
            options={regions
              .map((region) => {
                if (region._id === region || Object.keys(regionalProjectTypes).includes(region._id))
                  return null;
                return {
                  value: region._id,
                  label: region.name_en || region.name_de || region.name_fr || region.name_it
                };
              })
              .filter((value) => value !== null)}
            onChange={(e) => {
              duplicateClassProjectTypeForRegion(classProjectTypes, e.value, region);
            }}
          />
        </div>
      )}

      {displayToggle?.[`projectTypes-${region}`] &&
        Object.keys(classProjectTypes).map((variant, index) => {
          const variantDetails = classProjectTypes[variant];
          const variantTitle =
            variantDetails?.title?.en ||
            variantDetails?.title?.fr ||
            variantDetails?.title?.de ||
            variantDetails?.title?.it;

          return (
            <div key={index} className="my-10 border-[1px] border-[#606060] rounded-md p-4">
              <div class="flex items-center justify-between">
                <p
                  className="w-full text-lg font-bold mb-2 cursor-pointer select-none"
                  onClick={() => {
                    setDisplayToggle({
                      ...displayToggle,
                      [`classProjectType-${region}-${variant}`]:
                        !displayToggle?.[`classProjectType-${region}-${variant}`]
                    });
                  }}>
                  {variantTitle}
                </p>
                <ToggleComponent
                  handleCompToggle={() => toggleClassProjectTypeEnable(variant, region)}
                  isToggle={variantDetails?.enabled}
                />
              </div>

              <div className="flex items-end justify-end">
                <Input
                  className={"w-full"}
                  inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                  labelClassName={"text-sm font-normal text-[#606060]"}
                  label={"List Order"}
                  placeholder={"0"}
                  value={variantDetails?.order}
                  onChange={(e) => {
                    setOrderOfClassProject(variant, region, e.target.value);
                  }}
                />
              </div>

              <div
                className={`${
                  displayToggle?.[`classProjectType-${region}-${variant}`] ? "block" : "hidden"
                }`}>
                {/* Variant titles */}
                <div className="flex flex-row gap-1 my-2">
                  {["en", "de", "fr", "it"].map((lang) => {
                    return (
                      <Input
                        key={lang}
                        className={"w-full"}
                        inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                        labelClassName={"text-sm font-normal text-[#606060]"}
                        label={lang.toUpperCase() + " Title"}
                        placeholder={lang}
                        value={variantDetails?.title?.[lang]}
                        onChange={(e) => {
                          updateClassProjectTitle(
                            variant,
                            {
                              ...variantDetails.title,
                              [lang]: e.target.value
                            },
                            region
                          );
                        }}
                      />
                    );
                  })}
                </div>
                {/* Select profession map */}
                <ProfessionMapSelection
                  professionMaps={professionMaps}
                  classProjectTypes={classProjectTypes}
                  setClassProjectTypes={setClassProjectTypes}
                  variant={variant}
                  variantDetails={variantDetails}
                  type={"classOverallProfessionMap"}
                />
                <ProfessionMapSelection
                  professionMaps={professionMaps}
                  classProjectTypes={classProjectTypes}
                  setClassProjectTypes={setClassProjectTypes}
                  variant={variant}
                  variantDetails={variantDetails}
                  type={"classGroupedProfessionMap"}
                />
                <ProfessionMapSelection
                  professionMaps={professionMaps}
                  classProjectTypes={classProjectTypes}
                  setClassProjectTypes={setClassProjectTypes}
                  variant={variant}
                  variantDetails={variantDetails}
                  type={"studentProfessionMap"}
                />
                {/* List Class page components */}
                <div className="px-4 py-3 bg-gray-100 rounded-lg">
                  <p
                    className="w-full text-lg font-bold my-2 cursor-pointer select-none"
                    onClick={() => {
                      setDisplayToggle({
                        ...displayToggle,
                        [`${variant}-${region}`]: {
                          classPage: !displayToggle?.[`${variant}-${region}`]?.classPage,
                          studentPage: displayToggle?.[`${variant}-${region}`]?.studentPage
                        }
                      });
                    }}>
                    Class Webpage UI
                  </p>
                  <div
                    className={`${displayToggle?.[`${variant}-${region}`]?.classPage ? "block" : "hidden"}`}>
                    <DragDropContext
                      onDragEnd={(result) => {
                        onDragEndClassStudentSetup("classPage", variant, result, region);
                      }}>
                      <Droppable droppableId={`classPage-${variant}-${region}-components`}>
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {variantDetails?.classPage?.map(
                              (classPageElement, classPageElementIndex) => (
                                <Draggable
                                  key={`classPage-${variant}-${classPageElement.id}-${classPageElementIndex}`}
                                  draggableId={`classPage-${variant}-${region}-${classPageElement.id}-${classPageElementIndex}`}
                                  index={classPageElementIndex}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      id={`classPage-${variant}-${region}-${classPageElement.id}-${classPageElementIndex}`}
                                      className="my-5">
                                      <ClassStudentComponents
                                        index={classPageElementIndex}
                                        region={region}
                                        type={"classPage"}
                                        projectVariant={variant}
                                        projectVariantTitle={variantTitle}
                                        componentType={classPageElement.id}
                                        backgroundHeading={classPageElement.backgroundHeading}
                                        updateBackgroundHeading={updateBackgroundHeading}
                                        foregroundHeading={classPageElement.foregroundHeading}
                                        updateForegroundHeading={updateForegroundHeading}
                                        subText={classPageElement.subText}
                                        updateSubText={updateSubText}
                                        buttonText={classPageElement.buttonText}
                                        updateButtonText={updateButtonText}
                                        html={classPageElement.html}
                                        updateHTMLBox={updateHTMLBox}
                                        videos={classPageElement.videos}
                                        updateVideosPlayerTitle={updateVideosPlayerTitle}
                                        updateVideosPlayerUrl={updateVideosPlayerUrl}
                                        addNewVideoInVideosPlayer={addNewVideoInVideosPlayer}
                                        deleteVideoInVideosPlayer={deleteVideoInVideosPlayer}
                                        videPlayerAptitudeCloudEnabled={
                                          classPageElement?.enableAttitudeCloud
                                        }
                                        toggleVideoPlayerAptitudeCloud={
                                          toggleVideoPlayerAptitudeCloud
                                        }
                                        regions={regions}
                                        selectedRegionalLinks={
                                          classPageElement?.selectedRegions || []
                                        }
                                        selectProjectTypeRegionalLink={
                                          selectProjectTypeRegionalLink
                                        }
                                        availableUsersGroups={systemAdminGroups}
                                        selectedUsersGroups={classPageElement?.selectedUsersGroups}
                                        selectUsersGroup={selectUsersGroup}
                                        professionTypes={professionTypes}
                                        selectedProfessionType={classPageElement?.professionType}
                                        selectProfessionType={selectProfessionType}
                                        useOrganizationStrengthsListInAptitudeCloud={
                                          classPageElement?.useOrganizationStrengthsList
                                        }
                                        toggleUseOrganizationStrengthsListInAptitudeCloud={
                                          toggleUseOrganizationStrengthsListInAptitudeCloud
                                        }
                                        useOrganizationRolesListInAptitudeCloud={
                                          classPageElement?.useOrganizationRolesList
                                        }
                                        toggleUseOrganizationRolesListInAptitudeCloud={
                                          toggleUseOrganizationRolesListInAptitudeCloud
                                        }
                                        aptitudeCloudOptions={
                                          classPageElement?.aptitudeCloudOptions
                                        }
                                        updateAptitudeCloudOption={updateAptitudeCloudOption}
                                        addAptitudeCloudOption={addAptitudeCloudOption}
                                        deleteAptitudeCloudOption={deleteAptitudeCloudOption}
                                        customVideoOptions={classPageElement?.customVideoOptions}
                                        addCustomVideoOption={addCustomVideoOption}
                                        deleteCustomVideoOption={deleteCustomVideoOption}
                                        updateCustomVideoOption={updateCustomVideoOption}
                                        removeComponent={deleteClassStudentComponent}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <ClassSudentComponentsPicker
                      type={"classPage"}
                      projectVariant={variant}
                      addComponent={(type, projectVariant, value) =>
                        addClassStudentComponent(type, projectVariant, value, region)
                      }
                    />
                  </div>
                </div>
                {/* List Student page components */}
                <div className="px-4 py-3 bg-gray-100 rounded-lg mt-5">
                  <p
                    className="w-full text-lg font-bold my-2 cursor-pointer"
                    onClick={() => {
                      setDisplayToggle({
                        ...displayToggle,
                        [`${variant}-${region}`]: {
                          classPage: displayToggle?.[`${variant}-${region}`]?.classPage,
                          studentPage: !displayToggle?.[`${variant}-${region}`]?.studentPage
                        }
                      });
                    }}>
                    Student Webpage UI
                  </p>
                  <div
                    className={`${displayToggle?.[`${variant}-${region}`]?.studentPage ? "block" : "hidden"}`}>
                    <DragDropContext
                      onDragEnd={(result) => {
                        onDragEndClassStudentSetup("studentPage", variant, result, region);
                      }}>
                      <Droppable droppableId={`studentPage-${region}-${variant}-components`}>
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {variantDetails?.studentPage?.map(
                              (studentPageElement, studentPageElementIndex) => (
                                <Draggable
                                  key={`studentPage-${variant}-${studentPageElement.id}-${studentPageElementIndex}`}
                                  draggableId={`studentPage-${region}-${variant}-${studentPageElement.id}-${studentPageElementIndex}`}
                                  index={studentPageElementIndex}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      id={`studentPage-${region}-${variant}-${studentPageElement.id}-${studentPageElementIndex}`}
                                      className="my-5">
                                      <ClassStudentComponents
                                        index={studentPageElementIndex}
                                        region={region}
                                        type={"studentPage"}
                                        projectVariant={variant}
                                        projectVariantTitle={variantTitle}
                                        componentType={studentPageElement.id}
                                        backgroundHeading={studentPageElement.backgroundHeading}
                                        updateBackgroundHeading={updateBackgroundHeading}
                                        foregroundHeading={studentPageElement.foregroundHeading}
                                        updateForegroundHeading={updateForegroundHeading}
                                        subText={studentPageElement.subText}
                                        updateSubText={updateSubText}
                                        buttonText={studentPageElement.buttonText}
                                        updateButtonText={updateButtonText}
                                        html={studentPageElement.html}
                                        updateHTMLBox={updateHTMLBox}
                                        videos={studentPageElement.videos}
                                        updateVideosPlayerTitle={updateVideosPlayerTitle}
                                        updateVideosPlayerUrl={updateVideosPlayerUrl}
                                        addNewVideoInVideosPlayer={addNewVideoInVideosPlayer}
                                        deleteVideoInVideosPlayer={deleteVideoInVideosPlayer}
                                        videPlayerAptitudeCloudEnabled={
                                          studentPageElement?.enableAttitudeCloud
                                        }
                                        toggleVideoPlayerAptitudeCloud={
                                          toggleVideoPlayerAptitudeCloud
                                        }
                                        regions={regions}
                                        selectedRegionalLinks={
                                          studentPageElement?.selectedRegions || []
                                        }
                                        selectProjectTypeRegionalLink={
                                          selectProjectTypeRegionalLink
                                        }
                                        availableUsersGroups={systemAdminGroups}
                                        selectedUsersGroups={
                                          studentPageElement?.selectedUsersGroups
                                        }
                                        selectUsersGroup={selectUsersGroup}
                                        professionTypes={professionTypes}
                                        selectedProfessionType={studentPageElement?.professionType}
                                        selectProfessionType={selectProfessionType}
                                        removeComponent={deleteClassStudentComponent}
                                        hideInPdf={studentPageElement?.hideInPdf}
                                        toggleShowClassStudentComponentInPdf={
                                          toggleShowClassStudentComponentInPdf
                                        }
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <ClassSudentComponentsPicker
                      type={"studentPage"}
                      projectVariant={variant}
                      addComponent={(type, projectVariant, value) =>
                        addClassStudentComponent(type, projectVariant, value, region)
                      }
                    />
                  </div>
                </div>

                <div className="mt-5 flex gap-3 justify-end">
                  <Button.AdminPanelButton
                    type="submit"
                    isSave={true}
                    loading={false}
                    bgColor="#515151"
                    textColor="#FFFFFF"
                    color="white"
                    onClick={saveTeacherAdminSetup}>
                    Save
                  </Button.AdminPanelButton>
                  {variant !== "default" && Object.keys(classProjectTypes)?.length > 1 && (
                    <Button.AdminPanelButton
                      type="button"
                      isSave={true}
                      loading={false}
                      bgColor="#515151"
                      textColor="#FFFFFF"
                      color="white"
                      onClick={() => deleteClassProject(variant, region)}>
                      Delete
                    </Button.AdminPanelButton>
                  )}
                  <Button.AdminPanelButton
                    type="button"
                    isSave={true}
                    loading={false}
                    bgColor="#515151"
                    textColor="#FFFFFF"
                    color="white"
                    onClick={() => duplicateClassProject(variant, region)}>
                    Duplicate
                  </Button.AdminPanelButton>
                </div>
              </div>
            </div>
          );
        })}

      <div className="mt-5 flex justify-end gap-3">
        <Button.AdminPanelButton
          type="button"
          isSave={true}
          loading={false}
          bgColor="#515151"
          textColor="#FFFFFF"
          color="white"
          onClick={saveTeacherAdminSetup}>
          Save
        </Button.AdminPanelButton>
        {Object.keys(regionalProjectTypes).length !== regions.length && (
          <Button.AdminPanelButton
            type="button"
            isSave={true}
            loading={false}
            bgColor="#515151"
            textColor="#FFFFFF"
            color="white"
            onClick={() =>
              duplicateClassProjectTypeForRegion(
                classProjectTypes,
                regions
                  .map((region) => region._id)
                  .filter((region) => !Object.keys(regionalProjectTypes).includes(region))?.[0]
              )
            }>
            Duplicate
          </Button.AdminPanelButton>
        )}
        {/* Delete region */}
        {region !== "default" && (
          <Button.AdminPanelButton
            type="button"
            isSave={true}
            loading={false}
            bgColor="#515151"
            textColor="#FFFFFF"
            color="white"
            onClick={() => deleteRegionalClassProjectType(region)}>
            Delete
          </Button.AdminPanelButton>
        )}
      </div>
    </div>
  );
};

export default ClassProjectSetup;
